import React from "react";
import "./../App.css";
import UnitDispatchCard from "./UnitDispatchCard";

interface DispatchProps {
  resourcesQueuedForDispatch: ResourcesTableData[];
  sendDispatch: Function;
  address: string;
  updateAddress: Function;
  removeResourceFromDispatch: Function;
}

function Dispatch(props: DispatchProps) {
  return (
    <div className="Dispatch MajorComponent">
      <div className="DispatchInputFieldsBox">
        <div className="input-group DispatchInput">
          <div className="input-group-prepend">
            <span className="input-group-text" id="basic-addon1">
              Units
            </span>
          </div>
          <div className="unitsStagedForDispatch">
            {props.resourcesQueuedForDispatch.map((resource: ResourcesTableData) => {
              return (
                <UnitDispatchCard
                  removeResourceFromDispatch={props.removeResourceFromDispatch}
                  email={resource.email}
                  ID={resource.ID}
                  key={resource.email}
                />
              );
            })}
          </div>
        </div>

        <div className="input-group DispatchInput">
          <div className="input-group-prepend">
            <span className="input-group-text" id="basic-addon1">
              Address
            </span>
          </div>
          <input
            type="text"
            className="form-control"
            aria-label="Username"
            aria-describedby="basic-addon1"
            value={props.address}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => props.updateAddress(ev.target.value)}
          />
        </div>

        <div className="input-group DispatchInput">
          <div className="input-group-prepend">
            <span className="input-group-text" id="basic-addon1">
              Incident
            </span>
          </div>
          <input type="text" className="form-control" aria-label="Username" aria-describedby="basic-addon1" />
        </div>
        <div className="input-group DispatchInput">
          <div className="input-group-prepend">
            <span className="input-group-text" id="basic-addon1">
              OtherInfo
            </span>
          </div>
          <input type="text" className="form-control" aria-label="Username" aria-describedby="basic-addon1" />
        </div>
      </div>
      <div className="DispatchButtonParrentDiv">
        <button type="button" className="btn btn-primary DispatchButton" onClick={() => props.sendDispatch()}>
          Send Destination{" "}
          <img src="/images/icon.jpg" width="30" height="30" className="d-inline-block align-top" alt=""></img>
        </button>
      </div>
    </div>
  );
}

export default Dispatch;
