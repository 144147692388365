import React from "react";
import "./../App.css";
import Header from "./Header";

interface Props {
  ECCUserObject: firebaseUserObject;
}

function DemoPage(props: Props) {
  return (
    <div className="DemoPage">
      <Header />
      <h2>Access Restricted</h2>
      <h3>
        To gain access please sign-up for QuickRoute Enterprise at <a href="https://quickroute.us">quickroute.us</a>
      </h3>
      <h4>
        {" "}
        <a href="/">sign-out</a>
      </h4>
    </div>
  );
}

export default DemoPage;
