function mungeScheduledRoadOutages(rawScheduledRoadOutages_dict: RawScheduledRoadOutages_dict) {
  let mungedScheduledRoadOutages_dict: ScheduledRoadOutages_dict = {};
  Object.keys(rawScheduledRoadOutages_dict).forEach((roadOutageKey: string) => {
    const rawScheduledRoadOutage = rawScheduledRoadOutages_dict[roadOutageKey];
    if (rawScheduledRoadOutage.type !== "placeholderType") {
      const scheduledRoadOutage: ScheduledRoadOutage = {
        id: roadOutageKey,
        coords: [
          parseFloat(rawScheduledRoadOutage.coords.split(",")[0]),
          parseFloat(rawScheduledRoadOutage.coords.split(",")[1]),
        ],
        end: parseInt(rawScheduledRoadOutage.end),
        start: parseInt(rawScheduledRoadOutage.start),
        type: rawScheduledRoadOutage.type,
        user: rawScheduledRoadOutage.user,
      };
      mungedScheduledRoadOutages_dict[roadOutageKey] = scheduledRoadOutage;
    }
  });
  return mungedScheduledRoadOutages_dict;
}

export default mungeScheduledRoadOutages;
