import React from "react";
import axios from "axios";

function updateAlert(alertID: string, newAlertStatus: AlertStatus, alertPopupObject: any) {
  axios.get(
    "https://us-central1-quickroute-84835.cloudfunctions.net/ECCUserUpdateAlert_0_1_0?alertID=" +
      alertID +
      "&newAlertStatus=" +
      newAlertStatus
  );
  alertPopupObject.remove();
}

interface Props {
  alert: Alert;
  alertPopupObject: any;
}

function AlertPopup(props: Props) {
  const expiring = props.alert.timeToLive;
  const now = +new Date() / 1000;
  const minutesRemaining = Math.round((expiring - now) / 60);

  return (
    <div className="">
      <div className="">
        <h6 className="card-title">{props.alert.type}</h6>
        <p className="card-subtitle">{"expiring in " + minutesRemaining + " minutes"}</p>
        <div className="alert-buttons-container">
          <button
            onClick={() => updateAlert(props.alert.id, "open", props.alertPopupObject)}
            type="button"
            className="btn btn-success btn-sm alert-button"
          >
            Confirm Alert
          </button>
          <button
            onClick={() => updateAlert(props.alert.id, "closed", props.alertPopupObject)}
            type="button"
            className="btn btn-danger btn-sm alert-button"
          >
            Clear Alert
          </button>
        </div>
      </div>
    </div>
  );
}

export default AlertPopup;
