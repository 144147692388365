import React from "react";
import "./../App.css";
import Header from "./Header";
import Dispatch from "./Dispatch";
import Resources from "./Resources";
import Map from "./Map";
import dataLoaders from "./../helpers/dataLoaders";
import mungeGroupUserInfo_static from "./../helpers/mungeGroupUsersInfo_static";
import mungeGroupUserInfo_dynamic from "./../helpers/mungeGroupUsersInfo_dynamic";
import mungeAlerts from "./../helpers/mungeAlerts";
import mungeScheduledRoadOutages from "../helpers/mungeScheduledRoadOutages";
const firebase = require("firebase");

interface MainPageProps {
  ECCUserObject: firebaseUserObject;
  backendEndpoints: BackendEndpoints;
  userSignOut: null | Function;
}
interface MainPageState {
  mobileUserInfo_static_dict: null | MobileUserInfo_static_dict;
  mobileUserInfo_dynamic_dict: null | MobileUserInfo_dynamic_dict;
  resourcesQueuedForDispatch: ResourcesTableData[];
  address: string;
  updateInterval: any;
  alerts: any;
  ECCAppSettings: ECCAppSettings;
  scheduledRoadOutages_dict: ScheduledRoadOutages_dict;
}

// Component
class MainPage extends React.Component<MainPageProps, MainPageState> {
  constructor(props: MainPageProps) {
    super(props);
    this.state = {
      mobileUserInfo_static_dict: {},
      mobileUserInfo_dynamic_dict: {},
      resourcesQueuedForDispatch: [],
      address: "",
      updateInterval: null,
      alerts: {},
      scheduledRoadOutages_dict: {},
      ECCAppSettings: { fetchDataInterval_seconds: 5, showScheduledRoadOutages: false },
    };
  }

  componentDidMount() {
    this.setMobileUserInfo_static_dict_toState();
    this.setAllDynamicDataToState();
    const updateInterval: any = setInterval(() => {
      this.setAllDynamicDataToState();
    }, this.state.ECCAppSettings.fetchDataInterval_seconds * 1000);
    this.setState({ updateInterval: updateInterval });
  }

  setAllDynamicDataToState = async () => {
    const self = this;
    const ECCUserToken: any = await firebase.auth().currentUser.getIdToken();
    let ECCUserObject = self.props.ECCUserObject;
    ECCUserObject.authToken = ECCUserToken;
    const alerts_raw = await dataLoaders.getAlerts(self.props.backendEndpoints);
    const alerts: Alerts_dict = mungeAlerts(alerts_raw);

    const mobileUserInfo_dynamic_dict_raw = await dataLoaders.getMobileUserInfo_dynamic_dict(
      self.props.backendEndpoints,
      ECCUserObject
    );
    const mobileUserInfo_dynamic_dict: MobileUserInfo_dynamic_dict = mungeGroupUserInfo_dynamic(
      mobileUserInfo_dynamic_dict_raw
    );

    const rawScheduledRoadOutages_dict: RawScheduledRoadOutages_dict = await dataLoaders.getScheduledRoadOutages(
      self.props.backendEndpoints
    );
    const scheduledRoadOutages_dict = mungeScheduledRoadOutages(rawScheduledRoadOutages_dict);
    self.setState({
      alerts: alerts,
      mobileUserInfo_dynamic_dict: mobileUserInfo_dynamic_dict,
      scheduledRoadOutages_dict: scheduledRoadOutages_dict,
    });
  };

  setMobileUserInfo_static_dict_toState = async () => {
    const mobileUserInfo_static_dict_raw = await dataLoaders.getMobileUserInfo_static_dict(
      this.props.backendEndpoints,
      this.props.ECCUserObject
    );
    const mobileUserInfo_static_dict: MobileUserInfo_static_dict = mungeGroupUserInfo_static(
      mobileUserInfo_static_dict_raw
    );
    this.setState({ mobileUserInfo_static_dict: mobileUserInfo_static_dict });
  };

  addResourceToDispatch = (resource: ResourcesTableData) => {
    const self = this;
    let resourcesQueuedForDispatch: ResourcesTableData[] = self.state.resourcesQueuedForDispatch;
    resourcesQueuedForDispatch.push(resource);
    this.setState({ resourcesQueuedForDispatch: resourcesQueuedForDispatch });
  };

  removeResourceFromDispatch = (email: string) => {
    const self = this;
    const resourcesQueuedForDispatch: ResourcesTableData[] = self.state.resourcesQueuedForDispatch.filter(
      (resource: ResourcesTableData) => {
        if (resource.email !== email) {
          return true;
        } else {
          return false;
        }
      }
    );
    self.setState({ resourcesQueuedForDispatch: resourcesQueuedForDispatch });
  };

  updateAddress = (address: string) => {
    this.setState({ address: address });
  };

  sendDispatch = async () => {
    // Get coordinates of address with mapbox geocoding api
    const mapBoxGeoCodingURL =
      "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
      this.state.address +
      ".json?types=address&access_token=" +
      "pk.eyJ1IjoibWFwcGlza3lsZSIsImEiOiJ5Zmp5SnV3In0.mTZSyXFbiPBbAsJCFW8kfg";
    const geoCodingResponse = await fetch(mapBoxGeoCodingURL).then((response) => {
      return response.json();
    });
    const coordinates = geoCodingResponse.features[0].center;
    const lat = coordinates[0];
    const lon = coordinates[1];

    const ECCUserEmail = await firebase.auth().currentUser.email;
    const ECCUserIdToken = await firebase.auth().currentUser.getIdToken();

    const baseURL: string =
      "https://us-central1-quickroute-84835.cloudfunctions.net/ECCUserSendNotifications_0_1_0?" +
      "&address=" +
      this.state.address +
      "&lat=" +
      lat +
      "&lon=" +
      lon +
      "&ECCUserEmail=" +
      ECCUserEmail +
      "&ECCUserIdToken=" +
      ECCUserIdToken;

    let emailAddresses = "";
    let FCMTokens = "";
    this.state.resourcesQueuedForDispatch.forEach((resource: ResourcesTableData) => {
      emailAddresses = emailAddresses + resource.email + ",";
      FCMTokens = FCMTokens + resource.FCMToken + ",";
    });
    emailAddresses = emailAddresses.slice(0, -1); // remove the trailing comma
    FCMTokens = FCMTokens.slice(0, -1); // remove the trailing comma
    const url: string = baseURL + "&FCMTokens=" + FCMTokens + "&userEmails=" + emailAddresses;

    await fetch(url);
    this.setState({ address: "", resourcesQueuedForDispatch: [] });
    // TODO
    //this.setState({ notificationStatus: "Sent!" });
    //setTimeout(this.removeNotificationSentMessage, 2000);
  };

  updateECCAppSettings = (key: string, value: any) => {
    const self = this;
    if (key === "fetchDataInterval_seconds") {
      this.updateMapRefreshRate(value);
    } else if (key === "showScheduledRoadOutages") {
      let ECCAppSettings = self.state.ECCAppSettings;
      ECCAppSettings.showScheduledRoadOutages = !self.state.ECCAppSettings.showScheduledRoadOutages;
      self.setState({ ECCAppSettings: ECCAppSettings });
    }
  };

  updateMapRefreshRate = (updateIntervalLength: number) => {
    const self = this;
    clearInterval(this.state.updateInterval);
    const newUpdateInterval: any = setInterval(() => {
      this.setAllDynamicDataToState();
    }, updateIntervalLength * 1000);
    let ECCAppSettings: ECCAppSettings = self.state.ECCAppSettings;
    ECCAppSettings.fetchDataInterval_seconds = updateIntervalLength;
    this.setState({ ECCAppSettings: ECCAppSettings, updateInterval: newUpdateInterval });
  };

  render() {
    if (!this.state) {
      return null;
    }
    return (
      <div className="MainPage">
        <Header
          ECCUserObject={this.props.ECCUserObject}
          userSignOut={this.props.userSignOut}
          updateECCAppSettings={this.updateECCAppSettings}
          ECCAppSettings={this.state.ECCAppSettings}
        />
        <div className="row">
          <div className="DispatchAndResourcesColumn">
            <Dispatch
              resourcesQueuedForDispatch={this.state.resourcesQueuedForDispatch}
              sendDispatch={this.sendDispatch}
              address={this.state.address}
              updateAddress={this.updateAddress}
              removeResourceFromDispatch={this.removeResourceFromDispatch}
            />
            <Resources
              mobileUserInfo_static_dict={this.state.mobileUserInfo_static_dict}
              mobileUserInfo_dynamic_dict={this.state.mobileUserInfo_dynamic_dict}
              addResourceToDispatch={this.addResourceToDispatch}
              resourcesQueuedForDispatch={this.state.resourcesQueuedForDispatch}
            />
          </div>
          <div className="MapColumn">
            <Map
              ECCAppSettings={this.state.ECCAppSettings}
              mobileUserInfo_dynamic_dict={this.state.mobileUserInfo_dynamic_dict}
              mobileUserInfo_static_dict={this.state.mobileUserInfo_static_dict}
              addResourceToDispatch={this.addResourceToDispatch}
              alerts={this.state.alerts}
              scheduledRoadOutages_dict={this.state.scheduledRoadOutages_dict}
              resourcesQueuedForDispatch={this.state.resourcesQueuedForDispatch}
              updateAddress={this.updateAddress}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default MainPage;
