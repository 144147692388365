import React from "react";
import ReactDOM from "react-dom";
import "./../App.css";
import mapboxgl, { LngLatLike } from "mapbox-gl";
import emailToKey from "../helpers/emailToKey";
import VehicleLocationPopup from "./popups/VehicleLocationPopup";
import MapClickPopup from "./popups/MapClickPopup";
import AlertPopup from "./popups/AlertPopup";
import ScheduledRoadOutagePopup from "./popups/ScheduledRoadOutagePopup";

mapboxgl.accessToken = "pk.eyJ1IjoibWFwcGlza3lsZSIsImEiOiJ5Zmp5SnV3In0.mTZSyXFbiPBbAsJCFW8kfg";

interface MapProps {
  mobileUserInfo_dynamic_dict: MobileUserInfo_dynamic_dict;
  mobileUserInfo_static_dict: MobileUserInfo_static_dict;
  addResourceToDispatch: any;
  alerts: Alerts_dict;
  scheduledRoadOutages_dict: ScheduledRoadOutages_dict;
  resourcesQueuedForDispatch: ResourcesTableData[];
  updateAddress: Function;
  ECCAppSettings: ECCAppSettings;
}

interface MapState {
  map: any;
  alertMarkers: any[];
  vehicleLocationMarkers_dict: { [key: string]: any };
  alertMarkers_dict: { [key: string]: any };
  alerts_dict: { [key: string]: any };
  scheduledRoadOutageMarkers_dict: { [key: string]: any };
  scheduledRoadOutages_dict: ScheduledRoadOutages_dict;
  addressOfMapClick: string | null;
}

class Map extends React.Component<MapProps, MapState> {
  constructor(props: MapProps) {
    super(props);
    this.state = {
      map: null,
      alertMarkers: [],
      vehicleLocationMarkers_dict: {},
      alertMarkers_dict: {},
      alerts_dict: {},
      scheduledRoadOutageMarkers_dict: {},
      scheduledRoadOutages_dict: {},
      addressOfMapClick: null,
    };
  }

  componentDidMount() {
    const self = this;
    const map = new mapboxgl.Map({
      container: "mapContainer",
      style: "mapbox://styles/mapbox/streets-v11",
      center: [-77.04, 38.9],
      zoom: 5,
      attributionControl: false,
    });

    map.on("load", function () {
      map.resize();
    });

    map.on("click", async function (e) {
      const targetClass = (e.originalEvent.target as Element).className;
      if (targetClass === "mapboxgl-canvas") {
        const address = await self.getAddress(e.lngLat);
        const mapClickPopup = new mapboxgl.Popup().setLngLat(e.lngLat).setHTML("<div id=map-click-popup><div>");
        mapClickPopup
          .on("open", function () {
            ReactDOM.render(
              <MapClickPopup
                mapClickEvent={e}
                address={address}
                setAddressToDispatch={self.postAddressFromMapClick}
                mapClickPopup={mapClickPopup}
              />,
              document.getElementById("map-click-popup")
            );
          })
          .addTo(map);
      }
    });

    self.setState({ map: map });
  }

  componentDidUpdate(prevProps: MapProps, prevState: MapState) {
    if (this.props !== prevProps) {
      this.plotVehicleLocations(this.state.map, prevProps);
      this.plotAlertLocations(this.state.map);
      if (this.props.ECCAppSettings.showScheduledRoadOutages) {
        this.plotScheduledRoadOutage(this.state.map);
      } else {
        this.removeScheduledRoadOutages();
      }
    }
  }

  getAddress = (lngLat: any): Promise<string> => {
    const self = this;
    return new Promise(async (resolve, reject) => {
      try {
        // Get coordinates of address with mapbox geocoding api
        const mapBoxGeoCodingURL =
          "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
          lngLat.lng +
          "," +
          lngLat.lat +
          ".json?types=address&access_token=" +
          "pk.eyJ1IjoibWFwcGlza3lsZSIsImEiOiJ5Zmp5SnV3In0.mTZSyXFbiPBbAsJCFW8kfg";
        const geoCodingResponse = await fetch(mapBoxGeoCodingURL).then((response) => {
          return response.json();
        });
        const feature = geoCodingResponse.features[0];
        const address = feature.place_name.replace(/,[^,]+$/, ""); // address with US removed;
        self.setState({ addressOfMapClick: address });
        resolve(address);
      } catch (e) {
        console.log("error with get address: ", e);
        resolve("address not found");
      }
    });
  };

  postAddressFromMapClick = (address: string, mapClickPopup: any) => {
    const self = this;
    mapClickPopup.remove();
    self.props.updateAddress(address);
  };

  vehicleInfoReady(currentProps: MapProps) {
    if (
      Object.keys(currentProps.mobileUserInfo_dynamic_dict).length > 0 &&
      Object.keys(currentProps.mobileUserInfo_static_dict).length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  resourceAvailableForDispatch(
    mobileUserInfo_dynamic: MobileUserInfo_dynamic,
    mobileUserInfo_static: MobileUserInfo_static,
    resourcesQueuedForDispatch: ResourcesTableData[]
  ): boolean {
    if (mobileUserInfo_dynamic.status !== "available") {
      return false;
    } else {
      const emailsQueuedForDispatch: string[] = resourcesQueuedForDispatch.map((resource: ResourcesTableData) => {
        return resource.email;
      });
      if (emailsQueuedForDispatch.includes(mobileUserInfo_static.email)) {
        return false;
      } else {
        return true;
      }
    }
  }

  onlyVehicleLocationChanged(
    mobileUserInfo_static: MobileUserInfo_static,
    mobileUserInfo_dynamic: MobileUserInfo_dynamic,
    prevProps: MapProps,
    vehicleLocationMarkers_dict: { [key: string]: any }
  ) {
    if (Object.keys(vehicleLocationMarkers_dict).includes(mobileUserInfo_static.email)) {
      if (
        prevProps.mobileUserInfo_dynamic_dict[emailToKey(mobileUserInfo_static.email)].status ===
        mobileUserInfo_dynamic.status
      ) {
        return true;
      }
    }
    return false;
  }

  addResourceToDispatch = (resource: any, vehiclePopupObject: any) => {
    const self = this;
    vehiclePopupObject.remove();
    const emailsQueuedForDispatch: string[] = self.props.resourcesQueuedForDispatch.map(
      (resource: ResourcesTableData) => {
        return resource.email;
      }
    );
    if (!emailsQueuedForDispatch.includes(resource.email)) {
      self.props.addResourceToDispatch(resource);
    }
  };

  plotVehicleLocations = (map: any, prevProps: MapProps) => {
    const self = this;
    if (this.vehicleInfoReady(self.props)) {
      let vehicleLocationMarkers_dict = self.state.vehicleLocationMarkers_dict;

      Object.keys(self.props.mobileUserInfo_dynamic_dict).forEach((mobileUserKey: string) => {
        try {
          const mobileUserInfo_dynamic: MobileUserInfo_dynamic = self.props.mobileUserInfo_dynamic_dict[mobileUserKey];
          const mobileUserInfo_static: MobileUserInfo_static = self.props.mobileUserInfo_static_dict[mobileUserKey];
          const resourceAvailableForDispatch: boolean = this.resourceAvailableForDispatch(
            mobileUserInfo_dynamic,
            mobileUserInfo_static,
            self.props.resourcesQueuedForDispatch
          );

          const lngLat: LngLatLike = [mobileUserInfo_dynamic.lon, mobileUserInfo_dynamic.lat];

          if (
            this.onlyVehicleLocationChanged(
              mobileUserInfo_static,
              mobileUserInfo_dynamic,
              prevProps,
              self.state.vehicleLocationMarkers_dict
            )
          ) {
            // if the marker was already added and the status didn't change, just update it's location and status... don't create a new one
            self.state.vehicleLocationMarkers_dict[mobileUserInfo_static.email].setLngLat(lngLat);
          } else {
            if (Object.keys(self.state.vehicleLocationMarkers_dict).includes(mobileUserInfo_static.email)) {
              self.state.vehicleLocationMarkers_dict[mobileUserInfo_static.email].remove();
            }
            let el = document.createElement("div");
            let className = "vehicle-marker";
            if (!resourceAvailableForDispatch) {
              className = "vehicle-marker-unavailable";
            }
            el.className = className;
            el.id = "vehicleMarker_" + mobileUserInfo_static.ID;

            const vehiclePopupObject = new mapboxgl.Popup({ offset: 25 })
              .setHTML(
                "<div class=vehicle-popup id=vehicle-popup_" + emailToKey(mobileUserInfo_static.email) + "></div>"
              )
              .on("open", function () {
                ReactDOM.render(
                  <VehicleLocationPopup
                    mobileUserInfo_static={mobileUserInfo_static}
                    mobileUserInfo_dynamic={mobileUserInfo_dynamic}
                    addToDispatch={self.addResourceToDispatch}
                    resourcesQueuedForDispatch={self.props.resourcesQueuedForDispatch}
                    vehiclePopupObject={vehiclePopupObject}
                  />,
                  document.getElementById("vehicle-popup_" + emailToKey(mobileUserInfo_static.email))
                );
              });

            const vehicleLocationMarker = new mapboxgl.Marker(el)
              .setLngLat(lngLat)
              .setPopup(vehiclePopupObject)
              .addTo(self.state.map);
            vehicleLocationMarkers_dict[mobileUserInfo_static.email] = vehicleLocationMarker;
          }
        } catch (e) {
          console.log(e);
        }
      });
      self.setState({
        vehicleLocationMarkers_dict: vehicleLocationMarkers_dict,
      });
    }
  };

  alertChanged(alert: Alert) {
    const self = this;
    if (Object.keys(self.state.alerts_dict).includes(alert.id)) {
      if (self.state.alerts_dict[alert.id].timeToLive === alert.timeToLive) {
        return false;
      }
    }
    return true;
  }

  plotAlertLocations(map: any) {
    const self = this;
    if (Object.keys(self.props.alerts).length > 0) {
      let alertMarkers_dict = self.state.alertMarkers_dict;
      let alerts_dict = self.state.alerts_dict;
      Object.keys(self.props.alerts).forEach((alertID: string) => {
        try {
          const alert: Alert = self.props.alerts[alertID];
          if (self.alertChanged(alert)) {
            let el = document.createElement("div");
            let className = "alertMarker ";
            className = className + "alert-" + alert.type;
            el.className = className;
            const lngLat: LngLatLike = [alert.coords[1], alert.coords[0]];

            const alertPopup = new mapboxgl.Popup()
              .setHTML("<div id=alert-popup_" + alert.id + "></div>")
              .on("open", function () {
                ReactDOM.render(
                  <AlertPopup alert={alert} alertPopupObject={alertPopup} />,
                  document.getElementById("alert-popup_" + alert.id)
                );
              });

            const alertMarker = new mapboxgl.Marker(el).setLngLat(lngLat).setPopup(alertPopup).addTo(self.state.map);
            alertMarkers_dict[alertID] = alertMarker;
            alerts_dict[alertID] = alert;
          }
        } catch (e) {
          console.log("error in plotAlertLocation: ", e);
        }
      });
      if (Object.keys(self.props.alerts).length < Object.keys(alerts_dict).length) {
        // TODO: make this more robust (could potentially get tricked if an alert is added and another one is removed)
        Object.keys(alerts_dict).forEach((alertID: string) => {
          if (!Object.keys(self.props.alerts).includes(alertID)) {
            alertMarkers_dict[alertID].remove();
            delete alertMarkers_dict[alertID];
            delete alerts_dict[alertID];
          }
        });
      }
      self.setState({ alertMarkers_dict: alertMarkers_dict, alerts_dict: alerts_dict });
    }
  }

  scheduledRoadOutageChanged(scheduledRoadOutage: ScheduledRoadOutage) {
    const self = this;
    if (Object.keys(self.state.scheduledRoadOutages_dict).includes(scheduledRoadOutage.id)) {
      if (self.state.scheduledRoadOutages_dict[scheduledRoadOutage.id].start === scheduledRoadOutage.start) {
        return false;
      }
    }
    return true;
  }

  plotScheduledRoadOutage(map: any) {
    const self = this;
    if (Object.keys(self.props.scheduledRoadOutages_dict).length > 0) {
      let scheduledRoadOutageMarkers_dict = self.state.scheduledRoadOutageMarkers_dict;
      let scheduledRoadOutages_dict = self.state.scheduledRoadOutages_dict;
      Object.keys(self.props.scheduledRoadOutages_dict).forEach((alertID: string) => {
        try {
          const scheduledRoadOutage: ScheduledRoadOutage = self.props.scheduledRoadOutages_dict[alertID];
          if (self.scheduledRoadOutageChanged(scheduledRoadOutage)) {
            let el = document.createElement("div");
            let className = "alertMarker scheduledRoadOutage ";
            className = className + "alert-" + scheduledRoadOutage.type;
            el.className = className;
            const lngLat: LngLatLike = [scheduledRoadOutage.coords[1], scheduledRoadOutage.coords[0]];

            const alertPopup = new mapboxgl.Popup()
              .setHTML("<div id=scheduledRoadOutage-popup_" + scheduledRoadOutage.id + "></div>")
              .on("open", function () {
                ReactDOM.render(
                  <ScheduledRoadOutagePopup
                    scheduledRoadOutage={scheduledRoadOutage}
                    scheduledRoadOutagePopupObject={alertPopup}
                  />,
                  document.getElementById("scheduledRoadOutage-popup_" + scheduledRoadOutage.id)
                );
              });

            const scheduledRoadOutageMarker = new mapboxgl.Marker(el)
              .setLngLat(lngLat)
              .setPopup(alertPopup)
              .addTo(self.state.map);
            scheduledRoadOutageMarkers_dict[alertID] = scheduledRoadOutageMarker;
            scheduledRoadOutages_dict[alertID] = scheduledRoadOutage;
          }
        } catch (e) {
          console.log("error in plotAlertLocation: ", e);
        }
      });
      if (Object.keys(self.props.scheduledRoadOutages_dict).length < Object.keys(scheduledRoadOutages_dict).length) {
        // TODO: make this more robust (could potentially get tricked if an alert is added and another one is removed)
        Object.keys(scheduledRoadOutages_dict).forEach((alertID: string) => {
          if (!Object.keys(self.props.scheduledRoadOutages_dict).includes(alertID)) {
            scheduledRoadOutageMarkers_dict[alertID].remove();
            delete scheduledRoadOutageMarkers_dict[alertID];
            delete scheduledRoadOutages_dict[alertID];
          }
        });
      }
      self.setState({
        scheduledRoadOutages_dict: scheduledRoadOutages_dict,
        scheduledRoadOutageMarkers_dict: scheduledRoadOutageMarkers_dict,
      });
    }
  }

  removeScheduledRoadOutages = () => {
    const self = this;
    Object.keys(self.state.scheduledRoadOutageMarkers_dict).forEach((alertID: string) => {
      self.state.scheduledRoadOutageMarkers_dict[alertID].remove();
    });
    self.setState({ scheduledRoadOutageMarkers_dict: {}, scheduledRoadOutages_dict: {} });
  };

  render() {
    return (
      <div className="Map MajorComponent">
        <div className="MapOutsideContainer">
          <div id="mapContainer" className="MapContainer" />
        </div>
      </div>
    );
  }
}

export default Map;
