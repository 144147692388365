import React from "react";
import ReactTable6 from "react-table-6";

interface ResourcesProps {
  mobileUserInfo_static_dict: MobileUserInfo_static_dict;
  mobileUserInfo_dynamic_dict: MobileUserInfo_dynamic_dict;
  addResourceToDispatch: Function;
  resourcesQueuedForDispatch: any;
}

function Resources(props: ResourcesProps) {
  const columns = [
    {
      Header: "",
      accessor: "action",
      Cell: (row: any) => (
        <button
          className={"btn btn-light btn-sm ResourceTableDispatchButton_" + row.original.dispatchStatus}
          type="button"
          onClick={() => {
            props.addResourceToDispatch({
              email: row.original.email,
              FCMToken: row.original.FCMToken,
              ID: row.original.ID,
            });
          }}
          disabled={row.original.dispatchStatus === "Add" ? false : true}
        >
          {row.original.dispatchStatus}
        </button>
      ),
      filterable: false,
    },
    {
      Header: "ID",
      accessor: "ID",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Status",
      accessor: "status",
    },
  ];

  let resourcesData: ResourcesTableData[] = [];
  const emailsQueuedForDispatch = props.resourcesQueuedForDispatch.map((resource: ResourcesTableData) => {
    return resource.email;
  });
  Object.keys(props.mobileUserInfo_static_dict).forEach((resourceKey) => {
    let dispatchStatus: DispatchStatus = "Add";
    let email: string = props.mobileUserInfo_static_dict[resourceKey].email;
    if (emailsQueuedForDispatch.includes(email)) {
      dispatchStatus = "Added";
    }
    let status: MobileUserStatus = "unknown";
    if (
      props.mobileUserInfo_dynamic_dict.hasOwnProperty(resourceKey) &&
      props.mobileUserInfo_dynamic_dict[resourceKey].hasOwnProperty("status")
    ) {
      status = props.mobileUserInfo_dynamic_dict[resourceKey].status;
    }

    resourcesData.push({
      dispatchStatus: dispatchStatus,
      ID: props.mobileUserInfo_static_dict[resourceKey].ID,
      status: status,
      email: props.mobileUserInfo_static_dict[resourceKey].email,
      FCMToken: props.mobileUserInfo_static_dict[resourceKey].FCMToken,
    });
  });

  return (
    <div>
      <ReactTable6
        className="-striped Resources ResourcesTable MajorComponent"
        columns={columns}
        data={resourcesData}
        filterable
        showPagination={false}
      />
    </div>
  );
}

export default Resources;
