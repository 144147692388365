import React from "react";

interface Props {
  mobileUserInfo_static: MobileUserInfo_static;
  mobileUserInfo_dynamic: MobileUserInfo_dynamic;
  addToDispatch: Function;
  resourcesQueuedForDispatch: any;
  vehiclePopupObject: any;
}

function VehicleLocationPopup(props: Props) {
  const emailsQueuedForDispatch: string[] = props.resourcesQueuedForDispatch.map((resource: any) => {
    return resource.email;
  });
  const availableForDispatch: boolean =
    props.mobileUserInfo_dynamic.status !== "unavailable" &&
    props.mobileUserInfo_dynamic.status !== "routing" &&
    !emailsQueuedForDispatch.includes(props.mobileUserInfo_static.email);

  const dispatchObject: any = {
    email: props.mobileUserInfo_static.email,
    FCMToken: props.mobileUserInfo_static.FCMToken,
    ID: props.mobileUserInfo_static.ID,
  };
  return (
    <div className="">
      <div className="">
        <h6 className="card-title">{props.mobileUserInfo_static.ID}</h6>
        <p className="card-subtitle">{props.mobileUserInfo_dynamic.status}</p>
        {availableForDispatch ? (
          <div className="card-link">
            <button
              onClick={() => props.addToDispatch(dispatchObject, props.vehiclePopupObject)}
              type="button"
              className={"btn btn-dark btn-sm dispatchButton" + props.mobileUserInfo_static.ID}
            >
              Add To Dispatch
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default VehicleLocationPopup;
