import React from "react";
import "./../App.css";

interface UnitDispatchCardProps {
  email: string;
  ID: string;
  removeResourceFromDispatch: Function;
}
function UnitDispatchCard(props: UnitDispatchCardProps) {
  return (
    <div className="UnitDispatchCard">
      <div className="UnitDispatchCardText"> {props.ID}</div>
      <button
        className="removeUnitFromDispatch btn btn-light btn-sm"
        onClick={() => props.removeResourceFromDispatch(props.email)}
      >
        x
      </button>
    </div>
  );
}

export default UnitDispatchCard;
