import React from "react";

interface Props {
  postAlert: Function;
  mapClickEvent: any;
}

function AlertTypeSelector(props: Props) {
  return (
    <div className="alert-type-selector">
      <button
        className="postAlert-button alert-traffic"
        onClick={() => props.postAlert(props.mapClickEvent, "traffic")}
      />
      <button
        className="postAlert-button alert-flooding"
        onClick={() => props.postAlert(props.mapClickEvent, "flooding")}
      />

      <button
        className="postAlert-button alert-repair"
        onClick={() => props.postAlert(props.mapClickEvent, "repair")}
      />
      <button
        className="postAlert-button alert-accident"
        onClick={() => props.postAlert(props.mapClickEvent, "accident")}
      />

      <button
        className="postAlert-button alert-hazard"
        onClick={() => props.postAlert(props.mapClickEvent, "hazard")}
      />
      <button className="postAlert-button alert-edit" onClick={() => props.postAlert(props.mapClickEvent, "edit")} />
    </div>
  );
}

export default AlertTypeSelector;
