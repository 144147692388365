import React from "react";
import axios from "axios";

function cancelScheduledRoadOutage(scheduledRoadOutageID: string, scheduledRoadOutagePopupObject: any) {
  axios.get(
    "https://us-central1-quickroute-84835.cloudfunctions.net/ECCUserDeleteScheduledRoadOutage_0_1_0?passCode=G9QNrOwY3hfIRxpYFsbw&scheduledRoadOutageID=" +
      scheduledRoadOutageID
  );
  scheduledRoadOutagePopupObject.remove();
}

interface Props {
  scheduledRoadOutage: ScheduledRoadOutage;
  scheduledRoadOutagePopupObject: any;
}

function ScheduledRoadOutagePopup(props: Props) {
  const start = props.scheduledRoadOutage.start;
  const now = +new Date() / 1000;
  const minutesRemainingUntilActivation = Math.round((start - now) / 60);
  const hoursRemainingUntilActivation = Math.round(minutesRemainingUntilActivation / 60);
  const daysRemainingUntilActivation = Math.round(hoursRemainingUntilActivation / 24);
  let timeRemainingText = "";
  if (minutesRemainingUntilActivation < 120) {
    timeRemainingText = "Activated in " + minutesRemainingUntilActivation + " minutes";
  } else if (hoursRemainingUntilActivation < 48) {
    timeRemainingText = "Activated in " + hoursRemainingUntilActivation + " hours";
  } else {
    timeRemainingText = "Activated in " + daysRemainingUntilActivation + " days";
  }

  const duration_minutes = Math.round(props.scheduledRoadOutage.end - props.scheduledRoadOutage.start) / 60;
  const duration_hours = Math.round(duration_minutes / 60);
  const duration_days = Math.round(duration_hours / 24);
  let durationText = "";
  if (duration_minutes < 120) {
    durationText = "Will last for " + duration_minutes + " minutes";
  } else if (duration_hours < 48) {
    durationText = "Will last for " + duration_hours + " hours";
  } else {
    durationText = "Will last for " + duration_days + " days";
  }

  return (
    <div className="">
      <div className="">
        <h6 className="card-title">{props.scheduledRoadOutage.type}</h6>
        <p className="card-subtitle">{timeRemainingText}</p>
        <p className="card-subtitle">{durationText}</p>
        <div className="alert-buttons-container">
          <button
            onClick={() =>
              cancelScheduledRoadOutage(props.scheduledRoadOutage.id, props.scheduledRoadOutagePopupObject)
            }
            type="button"
            className="btn btn-danger btn-sm alert-button"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default ScheduledRoadOutagePopup;
