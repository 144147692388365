import React from "react";
import axios from "axios";
import "./App.css";
import Authentication from "./components/Authentication";
import MainPage from "./components/MainPage";
import DemoPage from "./components/DemoPage";
import Header from "./components/Header";

const firebase = require("firebase");

interface AppProps {}
interface AppState {
  verifiedUserSignedIn: boolean;
  ECCGroupUserSignedIn: boolean;
  ECCUserObject: firebaseUserObject;
  backendEndpoints: BackendEndpoints;
}

class App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    let signinRequired = false;
    let ECCGroupUserSignedIn = false;
    if (process.env.NODE_ENV === "development") {
      // These are commented out below because the app needs a userToken to function... I'll see if I can automate getting that for faster development feedback in the future
      //signinRequired = true;
      //ECCGroupUserSignedIn = true;
    }
    this.state = {
      verifiedUserSignedIn: signinRequired,
      ECCGroupUserSignedIn: ECCGroupUserSignedIn,
      ECCUserObject: null,
      backendEndpoints: { ECCGroupUser: false },
    };
  }

  signInSuccessful = async (ECCUserObject: firebaseUserObject) => {
    const self = this;
    const ECCUserToken: any = await firebase.auth().currentUser.getIdToken();
    const backendEndpoints_raw: any = await axios.get(
      "https://us-central1-quickroute-84835.cloudfunctions.net/ECCUserGetEndpointsBasedOnAccess_0_1_0?passCode=XchcblV79FNXVcX8cY5N&userToken=" +
        ECCUserToken
    );
    const backendEndpoints: BackendEndpoints = backendEndpoints_raw.data;
    let newState: AppState = {
      verifiedUserSignedIn: true,
      ECCUserObject: ECCUserObject,
      ECCGroupUserSignedIn: false,
      backendEndpoints: { ECCGroupUser: false },
    };
    if (backendEndpoints.ECCGroupUser) {
      ECCUserObject.authToken = ECCUserToken;
      newState.ECCUserObject = ECCUserObject;
      newState.ECCGroupUserSignedIn = true;
      newState.backendEndpoints = backendEndpoints;
    }
    self.setState(newState);
  };

  userSignOutSetState = (signedOut: boolean) => {
    if (signedOut) {
      this.setState({ verifiedUserSignedIn: false });
    }
  };

  userSignOut = () => {
    const self = this;
    firebase
      .auth()
      .signOut()
      .then(function () {
        self.userSignOutSetState(true);
      });
  };

  render() {
    return (
      <div className="App">
        {this.state.verifiedUserSignedIn === false ? (
          <div>
            <Header />
            <br />
            <Authentication onSuccessfulSignin={this.signInSuccessful}></Authentication>
          </div>
        ) : this.state.ECCGroupUserSignedIn === false ? (
          <DemoPage ECCUserObject={this.state.ECCUserObject}></DemoPage>
        ) : (
          <MainPage
            ECCUserObject={this.state.ECCUserObject}
            backendEndpoints={this.state.backendEndpoints}
            userSignOut={this.userSignOut}
          ></MainPage>
        )}
      </div>
    );
  }
}

export default App;

//backendEndpoints={this.state.backendEndpoints}
