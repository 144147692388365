import emailToKey from "./emailToKey";

function mungeGroupUsersInfo_static(rawMobileUsersInfo: any): MobileUserInfo_static_dict {
  let mungedMobileUsersInfo: MobileUserInfo_static_dict = {};
  Object.keys(rawMobileUsersInfo).forEach((mobileUserID: string) => {
    const mobileUserData: any = rawMobileUsersInfo[mobileUserID];
    if (mobileUserDataIsValid(mobileUserData)) {
      const email: string = mobileUserData.email;
      const FCMToken: string = mobileUserData.FCMToken;
      let ID: string = email.split("@")[0];
      if (mobileUserData.hasOwnProperty("ID")) {
        ID = mobileUserData.ID;
      }
      mungedMobileUsersInfo[emailToKey(email)] = {
        email: email,
        FCMToken: FCMToken,
        ID: ID,
      };
    }
  });
  return mungedMobileUsersInfo;
}

function mobileUserDataIsValid(mobileUserData: any): boolean {
  let mobileUserDataValidity: boolean = false;
  try {
    if (mobileUserData.hasOwnProperty("FCMToken") && typeof mobileUserData.FCMToken == "string") {
      if (mobileUserData.hasOwnProperty("email") && typeof mobileUserData.email == "string") {
        if (mobileUserData.hasOwnProperty("groupMobile") && mobileUserData.groupMobile) {
          mobileUserDataValidity = true;
        }
      }
    }
    return mobileUserDataValidity;
  } catch {
    return false;
  }
}

export default mungeGroupUsersInfo_static;
