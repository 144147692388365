import React from "react";
import axios from "axios";

import AlertTypeSelector from "./AlertTypeSelector";
import RoadOutageInterface from "./RoadOutageInterface";

interface MapClickPopupProps {
  mapClickEvent: any;
  address: string | null;
  setAddressToDispatch: Function;
  mapClickPopup: any;
}

interface MapClickPopupState {
  alertType: AlertType;
  alertSelectorShown: boolean;
  dateRange: any;
  plannedForFuture: boolean;
}

class MapClickPopup extends React.Component<MapClickPopupProps, MapClickPopupState> {
  constructor(props: MapClickPopupProps) {
    super(props);
    this.state = {
      alertType: "hazard",
      alertSelectorShown: false,
      dateRange: {},
      plannedForFuture: false,
    };
  }

  postAlert = (mapClickEvent: any, alertType: AlertType) => {
    const self = this;
    this.props.mapClickPopup.remove();
    const latLonString = mapClickEvent.lngLat.lat + ", " + mapClickEvent.lngLat.lng;
    if (self.state.plannedForFuture) {
      axios.get(
        "https://us-central1-quickroute-84835.cloudfunctions.net/ECCUserPostScheduledRoadOutage_0_1_0?passCode=mORpQByYznIkeanqCLnt&latLonString=" +
          latLonString +
          "&user=ECC&type=" +
          alertType +
          "&start=" +
          self.state.dateRange.startDate.getTime() / 1000 +
          "&end=" +
          self.state.dateRange.endDate.getTime() / 1000
      );
    } else {
      axios.get(
        "https://us-central1-quickroute-84835.cloudfunctions.net/ECCUserPostAlert_0_1_0?passCode=mORpQByYznIkeanqCLnt&latLonString=" +
          latLonString +
          "&user=ECC&type=" +
          alertType
      );
    }
  };

  openAlertTypeSelector() {
    this.setState({ alertSelectorShown: true });
  }

  onDateRangeChange = (dateRange: any) => {
    const self = this;
    self.setState({ dateRange: dateRange, plannedForFuture: true });
  };

  render() {
    const self = this;
    return (
      <div>
        <h6 className="card-title">{self.props.address}</h6>
        <p className="card-subtitle"></p>
        <div className="mapClick-buttons-container">
          {!self.state.alertSelectorShown ? (
            <div>
              <button
                onClick={() => self.props.setAddressToDispatch(self.props.address, self.props.mapClickPopup)}
                type="button"
                className="btn btn-dark btn-sm mapClick-button"
              >
                Set Address To Dispatch
              </button>
              <button
                onClick={() => self.openAlertTypeSelector()}
                type="button"
                className="btn btn-warning btn-sm mapClick-button"
              >
                Report Alert Here
              </button>
            </div>
          ) : (
            <div className="alertSelectorParent">
              <AlertTypeSelector mapClickEvent={self.props.mapClickEvent} postAlert={self.postAlert} />
              <RoadOutageInterface onDateRangeChange={this.onDateRangeChange} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default MapClickPopup;
