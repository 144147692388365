import axios from "axios";

function getMobileUserInfo_static_dict(backendEndpoints: BackendEndpoints, ECCUserObject: firebaseUserObject) {
  return new Promise<any>(async (resolve, reject) => {
    const getUsersInfoBaseURL =
      "https://us-central1-quickroute-84835.cloudfunctions.net/" + backendEndpoints.getUsersInfo;
    const getUsersInfoURL = getUsersInfoBaseURL + "?passCode=vTiMYprhJFYYGneEsZfQ&userToken=" + ECCUserObject.authToken;
    const generalUserInfo_raw = await axios.get(getUsersInfoURL);
    const generalUserInfo = generalUserInfo_raw.data;
    resolve(generalUserInfo);
  });
}

function getMobileUserInfo_dynamic_dict(backendEndpoints: BackendEndpoints, ECCUserObject: firebaseUserObject) {
  return new Promise<any>(async (resolve, reject) => {
    const getMobileUserInfo_dynamic_dict_BaseURL =
      "https://us-central1-quickroute-84835.cloudfunctions.net/" + backendEndpoints.getLocations;
    const getMobileUserInfo_dynamic_dict_URL =
      getMobileUserInfo_dynamic_dict_BaseURL + "?passCode=aLf5KqDCIqJFFY99R9GX&userToken=" + ECCUserObject.authToken;
    const getMobileUserInfo_dynamic_dict_raw = await axios.get(getMobileUserInfo_dynamic_dict_URL);
    const getMobileUserInfo_dynamic_dict = getMobileUserInfo_dynamic_dict_raw.data.locationsData;
    resolve(getMobileUserInfo_dynamic_dict);
  });
}

function getAlerts(backendEndpoints: BackendEndpoints) {
  return new Promise<any>(async (resolve, reject) => {
    const getAlerts_BaseURL = "https://us-central1-quickroute-84835.cloudfunctions.net/" + backendEndpoints.getAlerts;
    const getAlerts_URL = getAlerts_BaseURL; // no params for now but may add the option to only do your own state later
    const getAlerts_raw = await axios.get(getAlerts_URL);
    const alerts = getAlerts_raw.data;
    resolve(alerts);
  });
}

function getScheduledRoadOutages(backendEndpoints: BackendEndpoints) {
  return new Promise<any>(async (resolve, reject) => {
    const getScheduledRoadOutages_BaseURL =
      "https://us-central1-quickroute-84835.cloudfunctions.net/" + backendEndpoints.getScheduledRoadOutages;
    const getScheduledRoadOutages_URL = getScheduledRoadOutages_BaseURL + "?passCode=KZJY9JzfurG9kOXG0kFA"; // no params for now but may add the option to only do your own state later
    const getScheduledRoadOutages_raw = await axios.get(getScheduledRoadOutages_URL);
    const scheduledRoadOutages = getScheduledRoadOutages_raw.data;
    resolve(scheduledRoadOutages);
  });
}

const dataLoaders: DataLoaders = {
  getMobileUserInfo_static_dict: getMobileUserInfo_static_dict,
  getMobileUserInfo_dynamic_dict: getMobileUserInfo_dynamic_dict,
  getAlerts: getAlerts,
  getScheduledRoadOutages: getScheduledRoadOutages,
};
export default dataLoaders;
