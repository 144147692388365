function mungeAlerts(rawAlerts: any) {
  let mungedAlerts: Alerts_dict = {};
  rawAlerts.forEach((alert: RawAlert) => {
    try {
      const mungedAlert: any = alert;
      mungedAlert.coords = [parseFloat(alert.coords.split(",")[0]), parseFloat(alert.coords.split(",")[1])];
      mungedAlerts[alert.id] = mungedAlert;
    } catch (e) {}
  });
  return mungedAlerts;
}

export default mungeAlerts;
