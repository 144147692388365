import React from "react";
import "./../App.css";

// Firebase setup
const firebase = require("firebase");
const firebaseui = require("firebaseui");

const firebaseConfig = {
  apiKey: "AIzaSyA4YEgSkrj1REdlXAyZFvAvazn3yJpt9AE",
  authDomain: "quickroute-84835.firebaseapp.com",
  databaseURL: "https://quickroute-84835.firebaseio.com",
  projectId: "quickroute-84835",
  storageBucket: "quickroute-84835.appspot.com",
  messagingSenderId: "82237107814",
  appId: "1:82237107814:web:2ae95bdbafecdd07d447a7",
  measurementId: "G-35QLEEEH8Z",
};
firebase.initializeApp(firebaseConfig);
const ui = new firebaseui.auth.AuthUI(firebase.auth());

interface Props {
  onSuccessfulSignin: (ECCUserObject: firebaseUserObject) => void;
}
interface State {}

class Authentication extends React.Component<Props, State> {
  componentDidMount() {
    this.renderUserSignInUI();
  }

  renderUserSignInUI = () => {
    const self = this;
    ui.start("#firebaseui-auth-container", {
      signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
      credentialHelper: firebaseui.auth.CredentialHelper.NONE,
      callbacks: {
        signInSuccessWithAuthResult: function (authResult: any, redirectUrl: any) {
          let ECCUserObject: firebaseUserObject = firebase.auth().currentUser;
          if (!ECCUserObject.emailVerified) {
            ECCUserObject.sendEmailVerification()
              .then(function () {
                alert("Please verify you email address and then sign in again");
                self.revertBackToSignInPage();
              })
              .catch(function (error: string) {
                console.log("error: ", error);
              });
          } else {
            self.verifiedUserSignedIn(ECCUserObject);
          }
          return false;
        },
      },
    });
  };

  revertBackToSignInPage = () => {
    this.renderUserSignInUI();
  };

  verifiedUserSignedIn = (ECCUserObject: firebaseUserObject) => {
    this.props.onSuccessfulSignin(ECCUserObject);
  };

  render() {
    return (
      <div className="Authentication">
        <div id="firebaseui-auth-container"></div>
      </div>
    );
  }
}

export default Authentication;
