//TODO: Filter by timeStamp
function mungeGroupUsersInfo_dynamic(rawMobileUsersInfo: any): MobileUserInfo_dynamic_dict {
  let mungedMobileUsersInfo: MobileUserInfo_dynamic_dict = {};
  Object.keys(rawMobileUsersInfo).forEach((mobileUserID: string) => {
    const mobileUserData: any = rawMobileUsersInfo[mobileUserID];
    if (mobileUserInfo_dynamic_IsValid(mobileUserData)) {
      const lat: number = mobileUserData.lat;
      const lon: number = mobileUserData.lon;
      const timeStamp: number = mobileUserData.timeStamp;

      let status: MobileUserStatus = "unknown";
      if (mobileUserData.hasOwnProperty("status")) {
        status = mobileUserData.status;
      }

      mungedMobileUsersInfo[mobileUserID] = {
        lat: lat,
        lon: lon,
        status: status,
        timeStamp: timeStamp,
      };
    }
  });
  return mungedMobileUsersInfo;
}

function mobileUserInfo_dynamic_IsValid(mobileUserData: any): boolean {
  let mobileUserDataValidity: boolean = false;
  try {
    if (mobileUserData.hasOwnProperty("lat")) {
      if (mobileUserData.hasOwnProperty("lon")) {
        if (mobileUserData.hasOwnProperty("timeStamp")) {
          mobileUserDataValidity = true;
        }
      }
    }
    return mobileUserDataValidity;
  } catch {
    return false;
  }
}

export default mungeGroupUsersInfo_dynamic;
