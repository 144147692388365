import React from "react";
import "./../App.css";

interface Props {
  ECCUserObject?: firebaseUserObject;
  userSignOut?: null | Function;
  updateECCAppSettings?: Function;
  ECCAppSettings?: ECCAppSettings;
}

function Header(props: Props) {
  return (
    <div className="Header">
      <nav className="navbar navbar-light bg-light">
        <div className="navbar-brand">
          <img src="/images/icon.jpg" width="30" height="30" className="d-inline-block align-top" alt=""></img>{" "}
          QuickRoute ECC
        </div>
        {props.ECCUserObject ? (
          <div>
            <a
              className="nav-link dropdown-toggle settings-dropdown"
              href="./"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Settings
            </a>
            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
              <div> signed in as: {props.ECCUserObject.email}</div>
              <button onClick={() => props.userSignOut()}>signout</button>
              <br />

              <div>Map Refresh Rate in Seconds</div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  value="option1"
                  onClick={() => props.updateECCAppSettings("fetchDataInterval_seconds", 1)}
                />
                <label className="form-check-label">1</label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  value="option2"
                  onClick={() => props.updateECCAppSettings("fetchDataInterval_seconds", 5)}
                  defaultChecked={props.ECCAppSettings.fetchDataInterval_seconds === 5}
                />
                <label className="form-check-label">5</label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio3"
                  value="option3"
                  onClick={() => props.updateECCAppSettings("fetchDataInterval_seconds", 15)}
                />
                <label className="form-check-label">15 </label>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio4"
                    value="option4"
                    onClick={() => props.updateECCAppSettings("fetchDataInterval_seconds", 30)}
                  />
                  <label className="form-check-label">30 </label>
                </div>
              </div>

              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheck1"
                  onChange={(e) => props.updateECCAppSettings("showScheduledRoadOutages", "change")}
                />
                <label className="custom-control-label" htmlFor="customCheck1">
                  Show Scheduled Road Outages
                </label>
              </div>
            </div>
          </div>
        ) : null}
      </nav>
    </div>
  );
}

export default Header;
