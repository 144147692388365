import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";

import "./../../../node_modules/react-datepicker/dist/react-datepicker.css";

interface RoadOutageInterfaceProps {
  onDateRangeChange: Function;
}

interface RoadOutageInterfaceState {
  plannedForFuture: boolean;
  startDate: Date;
  endDate: Date;
}

class RoadOutageInterface extends React.Component<RoadOutageInterfaceProps, RoadOutageInterfaceState> {
  constructor(props: RoadOutageInterfaceProps) {
    super(props);
    const currentDate = new Date();
    const twoHoursFromNow = new Date(new Date().setTime(currentDate.getTime() + 120 * 1000 * 60));
    this.state = {
      plannedForFuture: false,
      startDate: currentDate,
      endDate: twoHoursFromNow,
    };
  }

  onStartChange(date: any) {
    const self = this;
    self.props.onDateRangeChange({ startDate: date, endDate: self.state.endDate });
    self.setState({ plannedForFuture: true, startDate: date });
  }

  onEndChange(date: any) {
    const self = this;
    self.props.onDateRangeChange({ startDate: self.state.startDate, endDate: date });
    self.setState({ plannedForFuture: true, endDate: date });
  }

  render() {
    const self = this;
    return (
      <div className="road-outage-interface">
        start:{" "}
        <DatePicker
          selected={self.state.startDate}
          onChange={(date: any) => self.onStartChange(date)}
          showTimeSelect
          minDate={moment().toDate()}
          dateFormat="MM/dd/yy HH:mm"
        />
        <br />
        end:{" "}
        <DatePicker
          selected={self.state.endDate}
          onChange={(date: any) => self.onEndChange(date)}
          showTimeSelect
          minDate={self.state.startDate}
          dateFormat="MM/dd/yy HH:mm"
        />
      </div>
    );
  }
}

export default RoadOutageInterface;
